@charset "utf-8";
@import "_format";
@import "_variables";
@import "_extend";
@import "_mixin";
/* ********************************************************
	add style CSS
******************************************************** */

html,
body {
	height: 100%;
}
body {
	-webkit-text-size-adjust: 100%;
	font-family: $font_yugo;
	font-feature-settings: "palt";
	&.open {
		overflow: hidden;
	}
}
a[href^="tel:"] {
	text-decoration: none;
	&:hover {
		cursor: default;
	}
}
#container {
	&:before {
		background: rgba($color-orange, 0);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		content: "";
		transform: scale(0);
		transition: all 0.3s ease;
		.open & {
			background: rgba($color-orange, 0.9);
			transform: scale(1);
		}
	}
}

/*-------------------------------
  Header
-------------------------------*/
#pageHeader {
	width: 100%;
	height: 0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	.headerInner {
		background: rgba(255, 255, 255, 0.8);
		height: 0;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		transition: all 0.3s ease;
		.open & {
			background: none;
		}
		.logo {
			margin: 27px 0 0 26px;
			padding-bottom: 20px;
			position: relative;
			z-index: 2;
			@include max-screen(900px) {
				padding-bottom: 5px;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				margin: 10px 0 0 16px;
			}
			a {
				path {
					transition: all 0.3s ease;
					.open & {
						fill: #fff;
					}
				}
			}
		}
		.snsArea {
			margin: 30px 0 0 auto;
			@include max-screen($breakpoint-mobile-portrait) {
				margin: 13px 0 0 auto;
			}
			ul {
				font-size: 0;
				li {
					display: inline-block;
					font-size: 1rem;
					a {
						&[href*="facebook"] {
							background: url("../images/common/icon_facebook.svg") center
								center no-repeat;
							width: 24px;
							height: 24px;
							display: block;
							overflow: hidden;
							text-indent: 1000%;
							white-space: nowrap;
						}
					}
				}
			}
		}
		#menuOpen {
			background: none;
			appearance: none;
			width: 46px;
			height: 46px;
			margin: 19px 25px 0;
			padding: 0;
			border: none;
			display: none; //
			overflow: hidden;
			position: relative;
			color: #000;
			cursor: pointer;
			@include max-screen(900px) {
				display: block;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				margin: 2px 16px 0;
			}
			.open & {
				background: rgba(255, 255, 255, 0);
			}
			&:before {
				height: 46px;
				margin: 0 auto;
				display: block;
				content: "MENU";
				font-size: 10px;
				@extend %fontEnBold;
				line-height: 46px;
				text-indent: 0;
				transition: all 0.3s ease;
				.open & {
					color: #fff;
					content: "CLOSE";
				}
			}
			&:after {
				width: 36px;
				height: 20px;
				margin: -12px 0 0 -18px;
				border-color: #000;
				border-style: solid none;
				border-width: 2px;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				content: "";
				transition: border-color 0.3s ease, transform 0.1s ease-out;
				transform-origin: center;
				.open & {
					border-color: #fff;
				}
			}
			&:hover:after {
				transform: scaleY(1.2);
			}
		}
	}

	&.header-bg-trans {
		.headerInner {
			background: none;
		}

		+ #navGlobal {
			ul > li > span > a {
				color: #fff;
				&:after {
					background: #fff;
				}
			}
		}
	}
}

/*-------------------------------
  global navigation
-------------------------------*/
#navGlobal {
	position: fixed;
	top: 35px;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	z-index: 1001;
	@include max-screen(900px) {
		visibility: hidden;
		top: 45vh;
		transform: translateY(-50%);
		justify-content: center;
		flex-direction: column;
		margin: 0 auto;
		left: 0;
	}
	ul {
		margin-right: 30px;
		@include max-screen(900px) {
			margin-right: 0;
		}
		li {
			list-style: none;
			display: inline-block;
			vertical-align: top;
			margin-right: 20px;
			@include max-screen(1024px) {
				margin-right: 12px;
			}
			@include max-screen(900px) {
				margin-right: 0;
				display: block;
				margin-top: 16px;
				text-align: center;
			}
			a {
				color: #000000;
				@extend %fontEnBoldI;
				font-weight: 800;
				font-size: 22px;
				line-height: 1.2;
				text-decoration: none;
				padding: 0 5px;
				position: relative;
				overflow: hidden;
				display: block;
				cursor: pointer;
				@include max-screen(1024px) {
					font-size: 19px;
				}
				@include max-screen(900px) {
					font-size: 24px;
					color: #fff;
					&:after {
						background: #fff !important;
					}
				}
				&:after {
					content: "";
					display: block;
					width: 0;
					height: 2px;
					transform: translate(-50%, -50%) skew(0, 10deg);
					background: #000;
					transition: width 0.25s cubic-bezier(0.5, 0.3, 0, 1); //ease-out
					position: absolute;
					top: 35%;
					left: 50%;
					margin: auto;
				}
				&:hover:after {
					width: 100%;
					@include max-screen(900px) {
						width: 0;
					}
				}
				span {
					display: inline-block;
					transform: translateY(150%);
				}
				&.highlight {
					color: #f15d30;
					@include sp {
						color: #fff;
					}
					&:after {
						background: #f15d30;
						@include sp {
							color: #fff;
						}
					}
				}
				.ruby {
					text-align: center;
					display: block;
					margin-top: 4px;
					font-size: 10px;
					line-height: 1;
					transform: none;
					font-style: normal;

					@include sp {
						display: none;
					}
				}
			}

			span.hnav-hover {
				position: relative;
				padding-bottom: 10px;
				&:hover {
					@include min-screen(901px) {
						span.hnav-list {
							opacity: 1;
							pointer-events: all;
						}
						a:after {
							width: 100%;
						}
					}
				}
			}

			span.hnav-list {
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				background: #fff;
				border-radius: 10px;
				box-shadow: 0 0 10px -2px rgba(#000, 0.2);
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 13px 18px;
				transition: ease-out 0.2s opacity;
				@include min-screen(901px) {
					opacity: 0;
					pointer-events: none;
				}
				@include max-screen(900px) {
					background: none;
					position: static;
					transform: none;
					box-shadow: none;
					padding: 0 15px;
				}

				span.hnav-list-item {
					@include sp {
						margin-top: 8px;
						display: block;
					}
					& + .hnav-list-item {
						@include sp {
							margin-top: 12px;
						}
					}
					a {
						font-weight: 500;
						font-size: 17px;
						padding: 5px 0;
						font-style: normal;
						transition: ease-out 0.1s color;
						word-break: keep-all;
						white-space: nowrap;
						@include max-screen(1024px) {
							font-size: 15px;
						}
						@include max-screen(900px) {
							padding: 3px 0;
						}

						&:after {
							content: none;
						}
						&:hover {
							color: $color-orange;
						}
					}
				}
			}
		}
	}
	.open & {
		visibility: visible;
		li a span {
			transition: transform 0.3s ease-in-out;
			transform: translateY(0);
			transform-origin: top;
		}
		@for $i from 1 through 8 {
			li:nth-child(#{$i}) a span {
				transition-delay: #{0.15 + $i * 0.04}s;
			}
		}
	}
}

.sp-sns {
	display: flex;
	margin-top: 25px;
	@include min-screen(901px) {
		display: none;
	}

	&Item {
		display: flex;
		align-items: baseline;
		text-decoration: none;
		margin: 0 6px;

		&Icon {
			width: 22px;
			margin-right: 4px;
			img {
				width: 100%;
			}
		}
		&Name {
			color: #fff;
			font-size: 12px;
			font-weight: 500;
			font-family: Montserrat, sans-serif;
		}
	}
}

/*-------------------------------
  contents
-------------------------------*/
#contents {
	//height: 1000px;
}

/*-------------------------------
  footer
-------------------------------*/
#pageFooter {
	background: #fff;
	padding: 0 50px;
	position: relative;
	@include max-screen(470px) {
		padding: 0 15px;
	}
	#pageTop {
		position: absolute;
		left: 50%;
		bottom: calc(100% + 60px);
		transform: translate(-50%, 0);
		&:after {
			background: #e6e6e6;
			width: 1px;
			height: 50px;
			display: block;
			position: absolute;
			top: calc(100% + 10px);
			left: 50%;
			content: "";
		}
		a {
			color: #969696;
			@extend %fontEnBook;
			font-size: 12px;
			text-decoration: none;
		}
	}
	.footerInner {
		padding: 50px 0 38px;
		border-top: solid 1px #e6e6e6;
		@extend .clearFix;
		@include max-screen(916px) {
			padding: 30px 0 10px;
		}
		.logo {
			float: left;
			@include max-screen(916px) {
				width: 30%;
				max-width: 205px;
				min-width: 124px;
				margin: 0 auto 20px;
				float: none;
				text-align: center;
			}
			a {
				width: 205px;
				height: 0;
				margin: -5px 0 0 -6px;
				padding-top: 29.8387%;
				display: block;
				position: relative;
				@include max-screen(916px) {
					width: 100%;
					margin: 0 auto;
				}
				img {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
		.navFooter {
			float: right;
			font-size: 0;
			text-align: center;
			@include max-screen(916px) {
				float: none;
			}
			@include max-screen(706px) {
			}
			li {
				display: inline-block;
				@include max-screen(706px) {
					line-height: 2;
				}
				+ li {
					margin-left: 40px;
					@include max-screen(1220px) {
						margin-left: 20px;
					}
					@include max-screen(1060px) {
						margin-left: 10px;
					}
					@include max-screen(706px) {
						margin-left: 0;
					}
				}
				&:not(:last-child):after {
					@include max-screen(706px) {
						margin: 0 5px;
						display: inline-block;
						font-size: 13px;
						vertical-align: sub;
						content: "/";
					}
				}
				a {
					color: #000;
					@extend %fontEnBoldI;
					font-size: 16px;
					line-height: 1;
					text-decoration: none;
					@include screen(916px, 1000px) {
						font-size: 14px;
					}
					@include screen(706px, 788px) {
						font-size: 14px;
					}
					@include max-screen(400px) {
						font-size: 13px;
					}
				}

				span.fnav-hover {
					position: relative;
					padding-top: 10px;
					display: block;
					@include max-screen($breakpoint-mobile) {
						display: inline-block;
					}
					a {
						cursor: pointer;
					}

					&:hover {
						span.fnav-list {
							opacity: 1;
							pointer-events: all;
						}
					}
				}

				span.fnav-list {
					position: absolute;
					bottom: 100%;
					left: 50%;
					transform: translateX(-50%);
					background: #fff;
					border-radius: 10px;
					box-shadow: 0 0 10px -2px rgba(#000, 0.2);
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 13px 18px;
					transition: ease-out 0.2s opacity;
					opacity: 0;
					pointer-events: none;
					@include max-screen($breakpoint-mobile) {
						padding: 10px 13px;
					}
				}

				span.fnav-list-item {
					display: inline-block;
					line-height: 1;
					a {
						font-weight: 500;
						font-size: 15px;
						padding: 5px 0;
						font-style: normal;
						transition: ease-out 0.1s color;
						word-break: keep-all;
						white-space: nowrap;
						display: block;
						@include max-screen($breakpoint-mobile) {
							font-size: 13px;
						}
						&:hover {
							color: $color-orange;
						}
					}
				}
			}
		}

		.footerRight {
			float: right;
			clear: right;
			display: flex;
			align-items: center;
			margin-top: 10px;
			@include max-screen($breakpoint-mobile) {
				display: block;
				float: none;
			}
		}

		.snsFooter {
			display: flex;
			margin-right: 25px;
			@include max-screen($breakpoint-mobile) {
				justify-content: center;
				margin-bottom: 15px;
				margin-right: 0;
			}
		}
		.snsFooterItem {
			text-decoration: none;
			display: flex;
			margin: 0 15px;
			@include max-screen($breakpoint-mobile) {
				margin: 0 8px;
			}

			&Icon {
				width: 15px;
				margin-right: 4px;
				img {
					width: 100%;
					height: auto;
				}
			}

			&Name {
				font-size: 14px;
				font-weight: 500;
				color: #000;
				font-family: $font-EN;
				@include max-screen($breakpoint-mobile) {
					font-size: 12px;
				}
			}

			&:hover {
				text-decoration: underline;
			}
		}

		#copyright {
			text-align: right;
			@extend %fontEnBook;
			font-size: 10px;
			font-weight: 500;
			@include max-screen(916px) {
				display: block;
				float: none;
				text-align: center;
			}
		}
	}
}
